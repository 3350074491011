<template>
  <div class="container-fluid" :class="{ loading: loading }">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>
              {{ `${$t("COMMON.SPOTS_MAP")}` }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>
                  {{ `${$t("COMMON.SPOTS_MAP")}` }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right"></div>
      </div>
      <div class="map-management">
        <div class="map-management-header">
          <p>{{ $t("COMMON.ADD_LOCATIONS_POINTS") }}</p>
          <image-selector
            :ressource_id="globalOrganization ? globalOrganization.id : 0"
            ressource_name="options"
            field="map_picture"
            @imageChanged="mapImageChanged"
            :label="`${$t('COMMON.ADD_MAP_IMAGE')}`"
            inline
          />
        </div>
        <div class="map-management-content">
          <div
            ref="mapContainer"
            class="image-container"
            :class="{ dragging: zoomOptions.isDragging }"
            @mousedown.prevent="startDragging"
            @mousemove.prevent="drag"
            @mouseup.prevent="mouseUp"
            @mouseleave.prevent="stopDragging"
            @wheel.prevent="zoom"
            @contextmenu.prevent=""
          >
            <img ref="imageContainer" :src="imageSrc" :style="imageStyle" />

            <div
              ref="mapFormContainer"
              v-if="showMapForm"
              class="map-form"
              :style="getMapFormStyle()"
            >
              <div class="map-form-header">
                <p>{{ $t("COMMON.ADD_LOCATION") }}</p>
                <button @click="closeMapForm" class="close">
                  <i class="fal fa-times"></i>
                </button>
              </div>
              <div class="map-form-content">
                <el-select
                  v-model="currentSpot.spotId"
                  :filterable="true"
                  :placeholder="`${$t('COMMON.SEARCH_LOCATION')}`"
                  @change="
                    (value) => {
                      updateSpotMapPosition(
                        value,
                        currentSpot.x,
                        currentSpot.y
                      );
                    }
                  "
                >
                  <el-option
                    v-for="spot in spots"
                    :key="spot.id"
                    :value="spot.id"
                    :label="spot.name"
                    :class="{
                      'spot-used':
                        spot.map_position_x != 0 && spot.map_position_y != 0,
                    }"
                  >
                  </el-option>
                </el-select>
                <div class="location">
                  <span>{{ $t("COMMON.SPOT") }}</span>
                  <div class="location-selected">
                    <div class="located" v-if="spots[currentSpot.spotId]?.id">
                      <div class="located-image">
                        <img
                          :src="
                            spots[currentSpot.spotId].category.icon
                              ? spots[currentSpot.spotId].category.icon
                              : '/img/default-category-icon.svg'
                          "
                          style="max-height: 35px; display: inline"
                          alt="icon"
                        />
                      </div>
                      <div class="located-text">
                        <span>{{ spots[currentSpot.spotId].code }}</span>
                        <span>
                          {{ spots[currentSpot.spotId].spotType.name }}
                        </span>
                      </div>
                      <div class="located-delete">
                        <button
                          @click="deleteSpotPosition(currentSpot.spotId)"
                          class="delete"
                        >
                          <img src="/img/e-trash.svg" alt="icon" />
                        </button>
                      </div>
                    </div>
                    <p v-else>{{ $t("COMMON.NONE") }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="currentSpot.x != 0 && currentSpot.y != 0"
              class="hotspot-current"
              :style="getHotspotStyle(currentSpot)"
            >
              <span class="hotspot-marker"></span>
            </div>

            <div
              v-for="(hotspot, index) in hotspots.filter(
                (item) => item.x != 0 && item.y != 0
              )"
              :key="index"
              class="hotspot"
              :style="getHotspotStyle(hotspot)"
              @click="onHotspotClick(hotspot)"
            >
              <span class="hotspot-marker"></span>
            </div>

            <div class="zoom-controls" ref="zoomControlsContainer">
              <button @click.stop="zoomIn">+</button>
              <button @click.stop="zoomOut">-</button>
            </div>
          </div>

          <div v-if="!organizationConfig.SPOTS_MAP_IMAGE_URL" class="no-image">
            <img src="/img/map-placeholder.svg" alt="map" />
            <p>{{ $t("COMMON.NO_IMAGE_ADDED") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="page-wrapper orga-select">
      <img src="/img/kw-fire.svg" alt="icon" />
      <p>{{ $t("COMMON.PLEASE_SELECT_ORGANIZATION") }}</p>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button, Option, Select } from "element-ui";
import { mapGetters } from "vuex";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ImageSelector from "@/components/ImageSelector.vue";
import { OPTIONS_SPOTS_MAP_IMAGE_URL } from "@/constants/options";

const markerDefaultWith = 15;
const markerDefaultHeight = 15;

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ImageSelector,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),

    imageStyle() {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      return {
        transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
        width: `${this.imageWidth}px`,
        height: `${this.imageHeight}px`,
      };
    },
  },

  watch: {
    organizationConfig: function () {
      this.loadImage();
    },
  },

  data() {
    return {
      zoomOptions: {
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        startX: 0,
        startY: 0,
        isDragging: false,
        hasDragged: false,
      },
      hotspots: [],
      spots: {},
      selectedHotspot: null,
      loading: false,
      showMapForm: false,
      currentSpot: {
        x: 0,
        y: 0,
      },
      imageSrc: "",
      imageWidth: 0,
      imageHeight: 0,
    };
  },

  mounted() {
    this.getSpotsList();
    this.loadImage();
  },

  methods: {
    loadImage() {
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      this.fetchImage(imageUrl);
    },

    fetchImage(url) {
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const calculateDimensions = (width, height) => {
            const maxDimension = 1000;
            let adjustedHeight = width;
            let adjustedWidth = height;
            if (width > height) {
              adjustedWidth = Math.ceil(width * (maxDimension / width));
              adjustedHeight = Math.ceil((adjustedWidth * height) / width);
            } else {
              adjustedHeight = Math.ceil(height * (maxDimension / height));
              adjustedWidth = Math.ceil((adjustedHeight * width) / height);
            }
            return { adjustedWidth, adjustedHeight };
          };
          const { adjustedWidth, adjustedHeight } = calculateDimensions(
            img.width,
            img.height
          );
          this.imageWidth = adjustedWidth;
          this.imageHeight = adjustedHeight;
          this.imageSrc = img.src;
          resolve(img);
        };
        img.onerror = () => {
          reject(new Error("Could not load image at " + imageUrl));
        };
        img.src = url;
      });
    },

    async mapImageChanged(file_url) {
      this.loading = true;
      await this.$store.dispatch("organizationOptions/updateOptionByKey", {
        key: OPTIONS_SPOTS_MAP_IMAGE_URL,
        value: file_url,
        organizationId: this.organizationConfig.id,
      });
      this.loading = false;
    },

    async getSpotsList() {
      try {
        this.loading = true;
        let params = {
          page: {
            number: 1,
            size: 999999,
          },
          include: "spotType,category",
        };
        await this.$store.dispatch("spots/list", params);
        const spotsList = this.$store.getters["spots/list"];

        this.hotspots = spotsList.map((item) => {
          return {
            x: item.map_position_x,
            y: item.map_position_y,
            spotId: item.id,
          };
        });
        const spotsMapIdSpot = {};
        for (const item of spotsList) {
          spotsMapIdSpot[item.id.toString()] = item;
        }
        this.spots = spotsMapIdSpot;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async updateSpotMapPosition(spotId, x, y) {
      try {
        this.loading = true;
        const spotData = cloneDeep(this.spots[spotId]);
        spotData.map_position_x = x;
        spotData.map_position_y = y;
        await this.$store.dispatch("spots/update", spotData);
        await this.getSpotsList();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteSpotPosition(spotId) {
      await this.updateSpotMapPosition(spotId, 0, 0);
      this.closeMapForm();
    },

    zoom(event) {
      event.preventDefault();
      const zoomSpeed = 0.1;
      const scaleFactor = event.deltaY > 0 ? -zoomSpeed : zoomSpeed;
      this.zoomOptions.scale = Math.min(
        Math.max(this.zoomOptions.scale + scaleFactor, 0.5),
        3
      );
    },

    zoomIn() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.min(this.zoomOptions.scale + zoomSpeed, 3);
    },

    zoomOut() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.max(
        this.zoomOptions.scale - zoomSpeed,
        0.5
      );
    },

    startDragging(event) {
      this.zoomOptions.isDragging = true;
      this.zoomOptions.startX = event.clientX - this.zoomOptions.offsetX;
      this.zoomOptions.startY = event.clientY - this.zoomOptions.offsetY;
    },

    drag(event) {
      if (this.zoomOptions.isDragging) {
        this.zoomOptions.hasDragged = true;
        this.zoomOptions.offsetX = event.clientX - this.zoomOptions.startX;
        this.zoomOptions.offsetY = event.clientY - this.zoomOptions.startY;
      }
    },

    mouseUp(event) {
      if (!this.zoomOptions.hasDragged) {
        this.zoomOptions.isDragging = false;
        this.addHotspot(event);
      }
      this.stopDragging();
    },

    stopDragging(event) {
      this.zoomOptions.isDragging = false;
      this.zoomOptions.hasDragged = false;
    },

    addHotspot(event) {
      if (this.showMapForm) {
        if (this.$refs.mapFormContainer.contains(event.target)) {
          return;
        }
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }
      const { scale } = this.zoomOptions;
      const rect = event.target.getBoundingClientRect();
      const x = (event.clientX - rect.x) / scale;
      const y = (event.clientY - rect.y) / scale;
      this.currentSpot = { x, y };
      this.showMapForm = true;
    },

    closeMapForm() {
      this.currentSpot = { x: 0, y: 0 };
      this.showMapForm = false;
    },

    getHotspotStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = hotspot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${markerDefaultWith}px`,
          height: `${markerDefaultHeight}px`,
        };
      }

      const markerWith = markerDefaultWith * scale;
      const markerHeight = markerDefaultHeight * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale +
        offsetWidth / 2 +
        offsetX -
        markerWith / 2;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight / 2;

      return {
        position: "absolute",
        left: `${newLeft}px`,
        top: `${newTop}px`,
        width: `${markerWith}px`,
        height: `${markerHeight}px`,
      };
    },

    getMapFormStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = this.currentSpot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${markerDefaultWith}px`,
          height: `${markerDefaultHeight}px`,
        };
      }

      const markerHeight = markerDefaultHeight * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale + offsetWidth / 2 + offsetX - 150;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight -
        186 -
        5;

      return {
        left: `${newLeft}px`,
        top: `${newTop}px`,
      };
    },

    onHotspotClick(hotspot) {
      this.currentSpot = hotspot;
      this.showMapForm = true;
    },
  },
};
</script>

<style scoped lang="scss">
.container-fluid.loading {
  cursor: progress;
}

.image-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 12px;
  background-color: #aec082;

  .map-form {
    width: 300px;
    min-height: 160px;
    background-color: #fff;
    filter: drop-shadow(0px 25px 50px #00000040);
    border-radius: 9px;
    position: absolute;
    z-index: 11;
    padding: 8px;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -12px;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 14px solid #fff;
    }
    &-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      position: relative;
      padding: 0 25px;
      p {
        color: #ef8152;
        position: relative;
        padding: 0 0 0 20px;
        font-family: "Greycliff CF Bold", sans-serif;
        margin: 0;
        &:before {
          content: "";
          width: 14px;
          height: 19px;
          background: url("/img/e-pin.svg") no-repeat center center;
          background-size: 14px 19px;
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
        }
      }
      button {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        i {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &-content {
      width: 100%;
      margin: 12px 0 0 0;
      display: flex;
      flex-direction: column;
      .el-select {
        width: 100%;
        .el-input {
          input {
            &.el-input__inner {
              min-height: 37px;
            }
          }
        }
      }
      .location {
        width: 100%;
        margin: 7px 0 0 0;
        display: flex;
        flex-direction: column;
        span {
          font-size: 13px;
          line-height: 16px;
          font-family: "Greycliff CF Bold", sans-serif;
        }
        &-selected {
          width: 100%;
          min-height: 55px;
          margin: 5px 0 0 0;
          border-radius: 6px;
          background-color: #aec0824d;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          .located {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 35px 10px 10px;
            position: relative;
            &-image {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              border: solid 1px #aec082;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            &-text {
              width: calc(100% - 35px);
              display: flex;
              flex-direction: column;
              padding: 0 0 0 10px;
              span {
                font-size: 12px;
                line-height: 15px;
                margin: 0 0 2px 0;
                color: #374151;
                font-family: "Greycliff CF Medium", sans-serif;
                &:first-child {
                  font-family: "Greycliff CF Bold", sans-serif;
                }
                &:last-of-type {
                  margin: 0;
                }
              }
            }
            &-delete {
              width: 35px;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: center;
              position: absolute;
              top: 0;
              right: 0;
              button {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #c81e1e;
                border: none;
                border-radius: 0 6px 6px 0;
                img {
                  widows: 16px;
                  height: 16px;
                }
              }
            }
          }
          p {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-family: "Greycliff CF Medium", sans-serif;
          }
        }
      }
    }
  }
}

.image-container.dragging {
  cursor: move;
}

img {
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
  user-select: none;
}

.zoom-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.zoom-controls button {
  background-color: #fff;
  //border: 1px solid #ccc;
  border: none;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 5px 0;
  display: flex;
  font-size: 30px;
  line-height: 34px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.01);
}

.zoom-controls button:hover {
  background-color: #eee;
}

.hotspot {
  position: absolute;
  background-color: #c1d0a1;
  border: solid 2px #7e8d6d;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.1s;
}

.hotspot-current {
  position: absolute;
  background-color: #0c8ce9;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.1s;
}

.hotspot-marker {
  display: inline-block;
  width: 100%;
  height: 100%;
}

li.el-select-dropdown__item.spot-used,
li.el-select-dropdown__item.spot-used:hover {
  background-color: #aec0824d;
}
</style>
